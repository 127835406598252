import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import About from '../containers/artistes';

type AboutPageProps = {};

const AboutPage: React.FunctionComponent<AboutPageProps> = () => {
  return (
    <Layout>
      <SEO
        title="Les Artistes, Galerie Sifrein Paris"
        description="Présentation des artistes exposant à la galerie Sifrein Paris"
      />

      <About />
    </Layout>
  );
};

export default AboutPage;
