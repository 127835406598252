import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import GatsbyImage from '../../components/gatsby-image';
import SocialProfile from '../../components/social-profile/social-profile';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
} from 'react-icons/io';
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from './style';
import Posts from './posts'

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/Sifrein/',
    tooltip: 'Facebook',
  },
  {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/galeriesifrein/',
    tooltip: 'Instagram',
  },
  {
    icon: <IoLogoTwitter />,
    url: 'https://twitter.com/sifrein',
    tooltip: 'Twitter',
  },
  // {
  //   icon: <IoLogoLinkedin />,
  //   url: 'https://www.linkedin.com/company/redqinc/',
  //   tooltip: 'Linked In',
  // },
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {
  // const Data = useStaticQuery(graphql`
  //   query {
  //     avatar: file(absolutePath: { regex: "/about.jpg/" }) {
  //       childImageSharp {
  //         gatsbyImageData(
  //           layout: FULL_WIDTH
  //           placeholder: BLURRED
  //           formats: [AUTO, WEBP, AVIF]
  //         )
  //       }
  //     }
  //     site {
  //       siteMetadata {
  //         author
  //         about
  //       }
  //     }
  //   }
  // `);

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>Les Artistes</h2>
        <p>
          
        </p>
      </AboutPageTitle>
      <Posts />
      {/* <AboutImage>
        <GatsbyImage src={Data.avatar.childImageSharp.gatsbyImageData} alt="about" />
      </AboutImage> */}
      <AboutDetails>
        <h2></h2>
        <p>
        </p>
        <p>
        </p>
        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default About;
